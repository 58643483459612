// /* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import { Link } from 'gatsby';
import Image from '../../../shared/Image';

// Redux actions and selectors

// Helpers & utils

// Style
import './index.scss';

import arrow from '../../../images/signaletique/dfbbhn 5.svg';
import map from '../../../images/signaletique/map.svg';
import arrowRight from '../../../images/signaletique/arrow-right.svg';
import tyneslogo from '../../../images/signaletique/tyness logo 1.svg';
/* -------------------------------------------------------------------------- */

/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Content() {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // State
  const { t } = useTranslation();
  const { banner, secondSection, thirdSection, forthSection } = t(
    'signaletique',
    {
      returnObjects: true,
    },
  );

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */

  /* ***************************** RENDER HELPERS ***************************** */

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="signaletique-content signaletique-content-container">
      <Container>
        <Row className="first-section scroll-reveal" justify="between">
          <Col lg={6} className="text-container">
            <p className="text">{banner.paragraph}</p>
            <p className="text bold">
              <strong>
                <i>{banner.quote}</i>
              </strong>
            </p>
            <hr style={{ color: '#DBDBDB' }} />
            <p className="author">
              <strong>{banner.author}</strong>
            </p>
            <p className="job">{banner.job}</p>
          </Col>
          <Col lg={5}>
            <div className="image">
              <img src={arrow} alt="" className="arrow" />
              <Image src="Group 498(1)" alt="" />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="second-section scroll-reveal">
        <Row align="center" gutterWidth={0}>
          <Col lg={6} className="d-flex justify-content-end order-2">
            <img src={map} alt="" />
          </Col>
          <Col lg={6}>
            <div className="right">
              <p className="title">
                <strong>{secondSection.title}</strong>
              </p>
              <p className="text">{secondSection.content[0].text}</p>
              <p className="text">{secondSection.content[1].text}</p>
            </div>
          </Col>
        </Row>
      </div>
      <Container className="third-section scroll-reveal">
        <Row align="center" gutterWidth={60}>
          <Col lg={6}>
            <p className="text">{thirdSection.content[0].text}</p>
            <p className="text">{thirdSection.content[1].text}</p>
          </Col>
          <Col lg={6}>
            <Image src="site-medina-kef" alt="" />
          </Col>
        </Row>
      </Container>
      <div className="forth-section scroll-reveal">
        <Container>
          <Row gutterWidth={90} align="center">
            <Col lg={6} className="img">
              <Image src="signaletique/Group 96" alt="" />
            </Col>
            <Col lg={6} className="text-container">
              <div>
                <img src={tyneslogo} alt="" />
              </div>
              <div className="d-flex align-items-center cta">
                <p className="cta-text">
                  <strong>{forthSection.ctaText}</strong>
                </p>
                <Link to="/">
                  <img src={arrowRight} alt="" />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

Content.propTypes = {};

Content.defaultProps = {};

export default Content;
