/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
// Lib UI components
import { Col, Container, Row } from 'react-grid-system';
// Style
import './index.scss';
// Assets
// import QUOTE from '../../../images/signaletique/quote.svg';
// import PILLARS from '../../../images/signaletique/pillars.svg';
import quote1 from '../../../images/signaletique/quote1.svg';
import quote2 from '../../../images/signaletique/quote2.svg';
import arrow from '../../../images/signaletique/arrow-white.svg';
import Image from '../../../shared/Image';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Hero() {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */
  // Localization
  const { t } = useTranslation();
  const { hero } = t('signaletique', { returnObjects: true });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */

  /* ***************************** RENDER HELPERS ***************************** */
  // function BackgroundShapes() {
  //   return (
  //     <>
  //       <img
  //         src={QUOTE}
  //         alt=""
  //         className="signaletique-hero__background-shape signaletique-hero__background-shape--1"
  //       />
  //       <img
  //         src={PILLARS}
  //         alt=""
  //         className="signaletique-hero__background-shape signaletique-hero__background-shape--2"
  //       />
  //       <img
  //         src={PILLAR}
  //         alt=""
  //         className="signaletique-hero__background-shape signaletique-hero__background-shape--3"
  //       />
  //     </>
  //   );
  // }

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="signaletique-hero scroll-reveal">
      <Container>
        <Row align="center" justify="start">
          <Col lg={6}>
            <div className="signaletique-hero-left">
              <div className="signaletique-hero-logo">
                <Image src="logo-tynes-white@2x 1" alt="Tynes" />
                <img src={arrow} alt="" className="arrow" />
              </div>
              <h3 className="signaletique-hero__title">{hero.title}</h3>
              <div className="d-flex align-items-start">
                <img src={quote1} alt="" className="first-quote" />
                <div>
                  <p className="signaletique-hero__description">
                    {hero.description}
                    <img src={quote2} alt="" className="float-right" />
                  </p>
                  <p className="signaletique-hero__author">{hero.author}</p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="op-0">
              <Image src="Asset 6@4x 1" alt="" />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="signaletique-hero-img">
        <Image src="Asset 6@4x 1" alt="" />
      </div>
    </div>
  );
}

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
